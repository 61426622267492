import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class ServiceTabExample extends Component {

    render() {

        /* service tab menu */
        let serviceTabMenuData = [
            { iconName: 'flaticon-002-welding', tabMenuName: 'Paving' },
            { iconName: 'flaticon-004-walkie-talkie', tabMenuName: 'Line Striping' },
            { iconName: 'flaticon-015-cart', tabMenuName: 'Seal Coating and Crack Filling' },
            { iconName: 'flaticon-010-tank-1', tabMenuName: 'Power Sweeping' },
            { iconName: 'flaticon-010-tank-1', tabMenuName: 'Snow Removal' },
            { iconName: 'flaticon-010-tank-1', tabMenuName: 'Power Washing' }
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i) => {
            return (
                <Tab key={i}>  <span className="text">{val.tabMenuName}</span></Tab>
            )
        });


        /* service tab content */

        let serviceTabContentData = [
            { bgUrl: "pavement-services.png", contentTitle: 'Paving', contentDesc: 'From your driveway to large scale commercial parking lot paving services , we have you covered. Increase your property value, ensure safe transit to your customers, maintain beautification and increase revenue.', serviceLink: 'paving-services' },
            { bgUrl: "line-striping.png", contentTitle: 'Line Striping', contentDesc: 'Pavement Markings are a necessity to our daily driving routine. Pavement Markings are very crucial and important part of our roads, parking lots, garages and highways in order for us to operate our vehicles safely.', serviceLink: 'line-striping-services' },
            { bgUrl: "sealcoating.png", contentTitle: 'Seal Coating and Crack Filling', contentDesc: 'Sealcoating is important to take under consideration where winter and summer temperatures vary drastically.  The sealer provides a protective compound that prevents oxidation, UV Rays , water, snow and vehicle traffic from damaging the asphalt.', serviceLink: 'sealcoating-services' },
            { bgUrl: "sweeping.png", contentTitle: 'Power Sweeping', contentDesc: 'Our team  delivers the best results with the latest equipment to remove  mold, grime, dust, mud, stains, and dirt from pavement, buildings and concrete surfaces.', serviceLink: 'sweeping-services' },
            { bgUrl: "snow-removal-service.jpeg", contentTitle: 'Snow Removal', contentDesc: 'Patriots Pavement Services, a trusted name in the industry, extends its expertise beyond pavement maintenance to offer top-notch snow removal services. Our snow removal business operates with the same dedication and commitment that has earned us a reputation for excellence in pavement care. Equipped with state-of-the-art snow plows, snow blowers, and a highly skilled team, we efficiently clear snow and ice from residential and commercial properties.', serviceLink: 'services' },
            { bgUrl: "IMG_6423.JPG", contentTitle: 'Power Washing', contentDesc: 'Patriots Pavement Services takes pride in offering comprehensive power washing services to meet all your exterior cleaning needs. As an extension of our renowned pavement care expertise, our power washing business is committed to delivering exceptional results with attention to detail and customer satisfaction.', serviceLink: 'sweeping-services' }
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i) => {
            return (
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(assets/img/backgrounds/${val.bgUrl})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">SEE MORE</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return (
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--120 grey-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h2 className="section-title section-space--bottom--50">Our Services
                                        {/*<span className="title-icon" />*/}
                                    </h2>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                {/* service tab wrapper */}

                                <div className="service-tab-wrapper">
                                    <Tabs>
                                        <div className="row no-gutters">
                                            <div className="col-md-4">
                                                <TabList>
                                                    <div className="service-tab__link-wrapper">
                                                        {serviceTabMenuDatalist}
                                                    </div>
                                                </TabList>
                                            </div>

                                            <div className="col-md-8">
                                                {serviceTabContentDatalist}
                                            </div>
                                        </div>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabExample;
