import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class Services extends Component {
    render() {

        let data = [
            { pageLink: 'paving-services', img: 'IMG_5966.JPG', iconClass: 'flaticon-002-welding', serviceTitle: 'Paving Service', serviceSubtitle: 'Our paving services are designed to create smooth and durable surfaces for roads, driveways, parking lots, and more. We specialize in excavating, grading, and compacting the ground before applying high-quality asphalt or concrete. Whether you need a new pavement or repairs to existing ones, our experienced team ensures the highest standards of craftsmanship and attention to detail.' },
            { pageLink: 'line-striping-services', img: 'linestripe.jpeg', iconClass: 'flaticon-004-walkie-talkie', serviceTitle: 'Line Striping Service', serviceSubtitle: 'Our line striping services help improve safety and organization by providing clear and visible markings on paved surfaces. We use premium quality paints and state-of-the-art equipment to create accurate and durable lines, symbols, and signage. From parking lot striping to road markings and athletic field layouts, we have the expertise to meet your specific needs.' },
            { pageLink: 'sealcoating-services', img: 'sealcoating.jpeg', iconClass: 'flaticon-002-welding', serviceTitle: 'Sealcoating Service', serviceSubtitle: 'Protect and enhance the longevity of your asphalt surfaces with our sealcoating services. Our skilled team applies a protective sealant that acts as a barrier against water, UV rays, chemicals, and normal wear and tear. This not only extends the life of your pavement but also improves its appearance. With our meticulous attention to detail, you can trust us to provide superior sealcoating solutions.' },
            { pageLink: 'sweeping-services', img: 'sweeping.jpeg', iconClass: 'flaticon-015-cart', serviceTitle: 'Sweeping Service', serviceSubtitle: 'Maintain the cleanliness and safety of your paved areas with our professional sweeping services. Our advanced equipment and trained operators efficiently remove debris, dirt, leaves, and other unwanted materials. Whether its a parking lot, construction site, or roadways, we ensure thorough sweeping to keep your surfaces clean and visually appealing.' },
        ];

        let Datalist = data.map((val, i) => {
            return (
                <div className="col-md-6 col-12 section-space--bottom--30 row serviceimg-padding" key={i}>
                    <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                <a href={`${process.env.PUBLIC_URL}`}>
                                    <img src={`assets/img/service/${val.img}`} className="img-fluid img-height" alt="Patriots Pavement Services" />
                                </a>
                            </div>
                            {/*  <div className="icon">
                        <i className={val.iconClass} />
                        </div> */}
                        </div>
                        <div className="service-grid-item__content">
                            <h3 className="title">
                                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.serviceTitle}</a>
                            </h3>
                            <p className="subtitle">{val.serviceSubtitle}</p>
                            {/*  <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">SEE MORE</a> */}
                        </div>
                    </div>
                    &nbsp;&nbsp;
                </div>
            )
        });

        return (
            <div>

                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Service</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Service</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-item-wrapper">
                                        <div className="row">
                                            {Datalist}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Brand logo
                <BrandLogoSlider background = "grey-bg" /> */}

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}

export default Services;
