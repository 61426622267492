import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import LineStripingServiceGallery from './components/LineStripingServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class LineStripingService extends Component {
    render() {
        return (
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Clear and Accurate Line Striping Services for Organized and Safe Surfaces</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/services`}>Services</a></li>
                                        <li>Line Striping Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        {/* service gallery */}
                                        <LineStripingServiceGallery />

                                        <div className="content section-space--top--30">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2>Service Description</h2>
                                                    Our line striping services cover a wide range of applications, including:
                                                    <br />
                                                    <b>Parking Lot Striping:</b> Properly marked parking spaces and traffic lanes are essential for efficient traffic flow and organized parking. We use premium quality paints and state-of-the-art equipment to create clear and accurate lines, arrows, symbols, and signage that comply with local regulations and ADA requirements.
                                                    <br />
                                                    <b>Roadway Markings:</b> Ensure the safety of drivers and pedestrians with our professional roadway line striping services. We create highly visible and durable markings, including centerlines, edge lines, crosswalks, stop bars, and directional arrows. Our precise techniques and quality materials contribute to enhanced traffic management and accident prevention.
                                                    <br />
                                                    <b>Athletic Field Layouts:</b> We specialize in creating precise and regulation-compliant line striping for athletic fields, including football fields, soccer fields, baseball diamonds, and more. Our team understands the specific requirements for each sport, ensuring accurate markings that enhance gameplay and minimize confusion.
                                                    <br />
                                                    <b>Warehouse and Industrial Floor Markings:</b> Efficiently organize your warehouse or industrial facility with our line striping services. We create clear markings for aisles, safety zones, loading docks, and forklift paths, helping to improve workflow, safety, and productivity within your space.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Quality Assurance</h2>
                                                    When you choose our line striping services, you can expect the following:
                                                    <br />
                                                    <b>Expertise:</b> Our skilled team has extensive experience in line striping projects of varying scales and complexities.
                                                    <br />
                                                    <b>Precision and Accuracy:</b> We utilize advanced equipment and techniques to ensure precise and accurate line striping for optimal visibility and compliance.
                                                    <br />
                                                    <b>High-Quality Materials:</b> We use durable and fade-resistant paints that withstand the elements and heavy traffic, ensuring long-lasting results.
                                                    <br />
                                                    <b>Compliance and Safety:</b> Our line striping services adhere to local regulations, ADA requirements, and industry best practices to maximize safety and accessibility.
                                                    <br />
                                                    <b>Timely Completion:</b> We strive to complete projects efficiently without compromising quality, meeting your timelines and minimizing disruptions.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Conclusion</h2>
                                                    At Patriots Pavement Services, we take pride in our line striping expertise, delivering clear and accurate markings that enhance safety and organization on your paved surfaces. Whether it's parking lot striping, roadway markings, athletic field layouts, or industrial floor markings, our experienced team is committed to exceeding your expectations. Contact us today to discuss your line striping needs and let us help you create a safer and more organized environment.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <div className="sidebar-wrapper">
                                        <div className="sidebar">
                                            <h3 className="sidebar-title">Line Striping Services</h3>
                                            <ul className="sidebar-list">
                                                At Patriots Pavement Services, we specialize in providing professional line striping services to enhance safety, organization, and functionality on paved surfaces. With our attention to detail, high-quality materials, and precise techniques, we ensure that your roadways, parking lots, and athletic fields have clear and durable markings. Trust our experienced team to deliver exceptional line striping solutions tailored to your specific needs.
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default LineStripingService;
