import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import SweepingServiceGallery from './components/SweepingServiceGallery';
class SweepingService extends Component {
    render() {

        return (
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Keep Your Surfaces Clean and Safe with Professional Sweeping Services</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/services`}>Services</a></li>
                                        <li>Sweeping Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        {/* service gallery */}
                                        <SweepingServiceGallery />

                                        <div className="content section-space--top--30">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2>Service Description</h2>
                                                    Our sweeping services cover a wide range of applications, including:
                                                    <br />
                                                    <b>Parking Lot Sweeping:</b> Our skilled team will meticulously sweep your parking lot to remove dirt, debris, leaves, and other litter that accumulate over time. This not only enhances the appearance of your property but also improves the overall safety by reducing slip and trip hazards.
                                                    <br />
                                                    <b>Roadway Sweeping:</b> We provide road sweeping services to remove loose gravel, debris, and other materials that can affect driving conditions and pose risks to vehicles and pedestrians. Our thorough sweeping techniques ensure clear and safe roadways.
                                                    <br />
                                                    <b>Construction Site Cleanup:</b> After construction or renovation projects, our sweeping services are essential to remove construction debris, dust, and loose materials from the site. We help you achieve a clean and presentable space while minimizing the impact on the surrounding environment.
                                                    <br />
                                                    <b>Event Cleanup:</b> Our sweeping services are available for event venues, ensuring a swift and efficient cleanup process after concerts, festivals, sports events, and other gatherings. We work closely with event organizers to schedule sweeping operations that meet their specific needs and timelines.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Quality Assurance</h2>
                                                    When you choose our sweeping services, you can expect the following:
                                                    <br />
                                                    <b>Advanced Equipment:</b> We utilize modern, high-capacity sweepers equipped with efficient filtration systems to capture fine particles and debris effectively.
                                                    <br />
                                                    <b>Trained Operators:</b> Our skilled operators are trained in proper sweeping techniques, ensuring thorough coverage and attention to detail.
                                                    <br />
                                                    <b>Environmentally Friendly Approach:</b> We adhere to environmentally responsible practices by properly disposing of collected debris and adhering to local regulations.
                                                    <br />
                                                    <b>Flexible Scheduling:</b> We work closely with our clients to determine the most convenient scheduling options, minimizing disruptions to your operations or events.
                                                    <br />
                                                    <b>Reliable Results:</b> Our goal is to provide consistently clean and well-maintained paved surfaces, enhancing the appearance and safety of your property.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Conclusion</h2>
                                                    <p>
                                                        Maintain clean and safe paved surfaces with our professional sweeping services. Patriots Pavement Services offers expert sweeping solutions for parking lots, roadways, construction sites, and events. Contact us today to discuss your sweeping needs and let us help you keep your premises clean, attractive, and hazard-free.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <div>
                                        <div className="sidebar-wrapper">
                                            <div className="sidebar">
                                                <h3 className="sidebar-title">Introduction</h3>
                                                <ul className="sidebar-list">
                                                    <p>
                                                        At Patriots Pavement Services, we understand the importance of maintaining clean and debris-free paved surfaces. Our professional sweeping services help keep roads, parking lots, and other paved areas free from dirt, leaves, and unwanted materials. With our state-of-the-art equipment and experienced operators, we ensure thorough and efficient sweeping to enhance the cleanliness and safety of your premises.
                                                    </p>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default SweepingService;