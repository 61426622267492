import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import SealCoatingServiceGallery from './components/SealCoatingServiceGallery';
class SealCoatingService extends Component {
    render() {

        return (
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Protect and Enhance Your Pavement with Professional Sealcoating Services</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/services`}>Services</a></li>
                                        <li>Sealcoating Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        {/* service gallery */}
                                        <SealCoatingServiceGallery />

                                        <div className="content section-space--top--30">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2>Service Description</h2>
                                                    Our sealcoating services encompass the following:
                                                    <br />
                                                    <b>Surface Preparation:</b> Before applying sealcoat, we ensure thorough surface cleaning and preparation. This includes removing dirt, debris, and vegetation, as well as repairing cracks and potholes to create a smooth and uniform surface.
                                                    <br />
                                                    <b>Premium Sealant Application:</b> We utilize high-quality sealants that provide a protective layer on your asphalt surfaces. Our sealants are carefully selected to resist damage from UV rays, water penetration, oil spills, and other elements that can deteriorate the pavement over time.
                                                    <br />
                                                    <b>Expert Application Techniques:</b> Our skilled team utilizes industry-approved techniques to apply sealcoat evenly and efficiently. We employ professional spraying or squeegee methods to achieve consistent coverage, ensuring optimal protection for your pavement.
                                                    <br />
                                                    <b>Enhanced Aesthetics:</b> In addition to the protective benefits, sealcoating enhances the appearance of your pavement. It provides a rich, dark black color that improves the overall curb appeal of your property.
                                                    <br />
                                                    <b>Maintenance and Reapplication:</b> Sealcoating is not a one-time process. To maintain its effectiveness, periodic reapplication is necessary. We can work with you to create a customized maintenance plan that ensures your pavement remains protected and looking its best for years to come.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Quality Assurance</h2>
                                                    When you choose our sealcoating services, you can expect the following:
                                                    <br />
                                                    <b>Experienced Professionals:</b> Our team consists of experienced sealcoating specialists who have a deep understanding of the materials and techniques required for optimal results.
                                                    <br />
                                                    <b>Premium Materials:</b> We use high-quality sealants that are specifically formulated for durability, flexibility, and resistance to various environmental factors.
                                                    <br />
                                                    <b>Attention to Detail:</b> From surface preparation to the precise application of sealcoat, we pay meticulous attention to every step of the process to ensure a smooth and uniform finish.
                                                    <br />
                                                    <b>Customer Satisfaction:</b> We are committed to delivering exceptional sealcoating services that meet or exceed your expectations. Your satisfaction is our priority.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Conclusion</h2>
                                                    <p>
                                                        Protect your asphalt surfaces and enhance their appearance with our professional sealcoating services. At Patriots Pavement Services, we bring expertise, quality materials, and attention to detail to every sealcoating project. Contact us today to discuss your sealcoating needs and let us help you extend the lifespan of your pavement while improving its overall aesthetics.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <div>
                                        <div className="sidebar-wrapper">
                                            <div className="sidebar">
                                                <h3 className="sidebar-title">Introduction</h3>
                                                <ul className="sidebar-list">
                                                    <p>
                                                        At Patriots Pavement Services, we understand the importance of protecting your asphalt surfaces from the damaging effects of water, sunlight, chemicals, and regular wear and tear. Our sealcoating services provide a reliable and cost-effective solution to extend the lifespan of your pavement while enhancing its appearance. Trust our experienced team to deliver superior sealcoating services tailored to your specific needs.
                                                    </p>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default SealCoatingService;