import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import axios from "axios";
class Contact extends Component {
    state = {
        name: '',
        email: '',
        message: '',
    };
    /* This is where the magic happens 
    */
    handleSubmit = event => {
        event.preventDefault();
        let data = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            to: "bvanesh135@gmail.com",
            subject: "Contact Submission Patriot Pavement Services"
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://jade-creponne-67df7b.netlify.app/.netlify/functions/api/send-email',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                window.location = "/success"
            })
            .catch((error) => {
                console.log(error);
            });
    }
    handleChangeName = event => {
        this.setState({ name: event.target.value });
    }
    handleChangeEmail = event => {
        this.setState({ email: event.target.value });
    }
    handleChangeMessage = event => {
        this.setState({ message: event.target.value });
    }
    render() {

        return (
            <div>

                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Contact Us</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  content page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                    {/*Contact section start*/}
                    <div className="conact-section">
                        <div className="container">
                            <div className="row section-space--bottom--50">
                                <div className="col">
                                    <div className="contact-map">
                                        <iframe title="Our Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188820.0433132515!2d-71.11036555714045!3d42.31451858944885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3652d0d3d311b%3A0x787cbf240162e8a0!2sBoston%2C%20MA!5e0!3m2!1sen!2sus!4v1577033147926!5m2!1sen!2sus" allowFullScreen=""></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-12">
                                    <div className="contact-information">
                                        <h3>Contact Information</h3>
                                        <ul>
                                            <li>
                                                <span className="icon"><i className="ion-android-map" /></span>
                                                <span className="text">295 Winter St STE 20,<span>Hanover MA 02339
                                                </span></span>
                                            </li>
                                            <li>
                                                <span className="icon"><i className="ion-ios-telephone-outline" /></span>
                                                <span className="text"><a href="tel:16178426647">1-617-842-6647</a></span>
                                            </li>
                                            <li>
                                                <span className="icon"><i className="ion-ios-email-outline" /></span>
                                                <span className="text"><a href="mailto:info@patriotpavementservices.com">info@patriotpavementservices.com</a></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-12">
                                    <div className="contact-form">
                                        <h3>Leave Your Message</h3>
                                        <form id="contact-form" onSubmit={this.handleSubmit}>
                                            <div className="row row-10">
                                                <div className="col-md-6 col-12 section-space--bottom--20"><input name="name" type="text" placeholder="Your Name" onChange={this.handleChangeName} /></div>
                                                <div className="col-md-6 col-12 section-space--bottom--20"><input name="email" type="email" placeholder="Your Email" onChange={this.handleChangeEmail} /></div>
                                                <div className="col-12"><textarea name="message" placeholder="Your Message" onChange={this.handleChangeMessage} /></div>
                                                <div className="col-12"><button type="submit">Send Message</button></div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Contac t section end*/}
                </div>
                {/*====================  End of content page content  ========== ==========*/}

                { /* Footer */}
                <Footer />

                {/* Mo bile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default Contact;
