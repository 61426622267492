import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'

class VideoCta extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }
    render(){
        return(
            <div>
                {/*====================  video cta area ====================*/}
                <div className="video-cta-area section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="video-cta-content">
                            <h4 className="video-cta-content__small-title">PATRIOT PAVEMENT SERVICES</h4>
                            <h3 className="video-cta-content__title">Commercial Parking Lot & Garage Services</h3>
                            <p className="video-cta-content__text">Patriot Pavement Services is a commercial and residential pavement service company. We offer pavement services throughout Greater Boston Area. Parking lot sweeping, parking lot line striping, sealcoating, paving and general construction services.
With over twelve years of experience our company prides itself in providing complete and personalized pavement services. With our unmatched customer service, quality of products and professionalism. Not to mention our competitive prices with state-of-the-art equipment and talented labor force. We have been providing our services to retails malls, parking lots ranging from small to large scale, roadway and highway markings as well as commercial garages. Our goal is to provide you with outstanding professional service of the highest competence.
</p>
                            <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn ht-btn--round">CONTACT US</a>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-6">
                            <div className="cta-video-image">
                            <div className="video-popup">

                                <button onClick={this.openModal}>
                                    <div className="cta-video-image__image">
                                        <img src="assets/img/backgrounds/three-line-striping.JPG" className="img-fluid" alt="" />
                                    </div>
                                    {/*
                                    <div className="cta-video-image__icon">
                                        <i className="ion-ios-play" />
                                    </div>
                                    */}
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of video cta area  ====================*/}
            </div>
        )
    }
}


export default VideoCta;
