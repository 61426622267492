import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class Portfolio extends Component {

    render() {

        let smallimg = [
            { img: 'IMG_5366.JPG' },
            { img: 'IMG_5525.JPG' },
            { img: 'IMG_5231.JPG' },
            { img: 'IMG_5232.JPG' },
            { img: 'IMG_5718.JPG' },
            { img: 'IMG_5941.JPG' },
            { img: 'IMG_7266.JPG' },
            { img: 'IMG_7203.HEIC' },
            { img: 'IMG_7166.HEIC' },
            { img: 'IMG_4613.JPG' }
        ]

        let data = [
            { img: 'IMG_4358.JPG' },
            { img: 'IMG_3483.JPG' },
            { img: 'IMG_3788.JPG' },
            { img: 'IMG_4847.JPG' },
            { img: 'IMG_4863.JPG' },
            { img: 'IMG_5262.JPG' },
            { img: 'IMG_5360.JPG' },
            { img: 'IMG_5526.JPG' },
            { img: 'IMG_5726.JPG' },
            { img: 'IMG_5760.JPG' },
            { img: 'IMG_5764.JPG' },
            { img: 'IMG_5765.JPG' },
            { img: 'IMG_5766.JPG' },
            { img: 'IMG_5769.JPG' },
            { img: 'IMG_5794.JPG' },
            { img: 'IMG_5796.JPG' },
            { img: 'IMG_5943.JPG' },
            { img: 'IMG_5945.JPG' },
            { img: 'IMG_5964.JPG' },
            { img: 'IMG_5966.JPG' },
            { img: 'IMG_5970.JPG' },
            { img: 'IMG_5972.JPG' },
            { img: 'IMG_6389.JPG' },
            { img: 'IMG_6399.JPG' },
            { img: 'IMG_6423.JPG' },
            { img: 'IMG_6880.HEIC' },
            { img: 'IMG_7190-1.JPG' },
            { img: 'IMG_7204.HEIC' },
            { img: 'IMG_7205.HEIC' },
            { img: 'IMG_7344.JPG' },
            { img: 'IMG_7611.JPG' },
            { img: 'IMG_7675.JPG' },
            { img: 'IMG_7676.JPG' },
            { img: 'IMG_7726.JPG' },
            { img: 'IMG_7934.JPG' },
            { img: 'IMG_8121.JPG' },
            { img: 'IMG_8510.JPG' },
            { img: 'IMG_8546.JPG' },
            { img: 'IMG_8655.JPG' },
            { img: 'IMG_8656.JPG' },
            { img: 'IMG_9402.JPG' },
            { img: 'IMG_9430.JPG' },
            { img: 'IMG_9436.JPG' },
            { img: 'IMG_4359.JPG' }
        ];

        let DatalistSmall = data.map((val, i) => {
            return (
                <div className="col-md-6 section-space--bottom--30 row serviceimg-padding" key={i}>
                    <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                <a href={`assets/img/portfolio/${val.img}`} target="_blank">
                                    <img src={`assets/img/portfolio/${val.img}`} className="img-fluid img-height" alt="Patriots Pavement Services" />
                                </a>
                            </div>
                        </div>
                    </div>
                    &nbsp;&nbsp;
                </div>
            )
        });

        let Datalist = data.map((val, i) => {
            return (
                <div className="col-md-6 col-12 section-space--bottom--30 row serviceimg-padding" key={i}>
                    <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                <a href={`assets/img/portfolio/${val.img}`} target="_blank">
                                    <img src={`assets/img/portfolio/${val.img}`} className="img-fluid img-height" alt="Patriots Pavement Services" />
                                </a>
                            </div>
                        </div>
                    </div>
                    &nbsp;&nbsp;
                </div>
            )
        });

        return (
            <div>

                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Portfolio</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Portfolio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                            <div className="row">
                                <div className="service-item-wrapper">
                                    <div className="row">
                                        {Datalist}
                                        {DatalistSmall}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Brand logo
                <BrandLogoSlider background = "grey-bg" /> */}

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default Portfolio;
