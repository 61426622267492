import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

class TestimonialSlider extends Component{
    render(){
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            effect: 'fade',
            autoplay: {
                delay: 3000
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            renderPagenation: () => (
                <div className="swiper-pagination"></div>
              ),
        }
        let data = [
            {testimonialImage: "1.jpg", testimonialName: "Tommy E. McCarver", testimonialDesignation: "Owner", testimonialText: "Patriots Pavement went above and beyond the call of duty, they finished our parking lot project on time and with virtually no impact to our customers "},
            {testimonialImage: "3.jpg", testimonialName: "Jon Richardson", testimonialDesignation: "General Manager", testimonialText: "Ronnie there at Patriots Pavement is awesome, he stands behind his work and never compromises customer service. I have done quite a few projects with this company"},
            {testimonialImage: "2.jpg", testimonialName: "Crystal L. Williams", testimonialDesignation: "Owner", testimonialText: "My Flower shop in Maine was in need of beautification outside our parking lines had disappeared and it needed a face lift. Patriots Pavement saved us a ton of money and now it looks new. "}
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide testimonial-slider__single-slide" key={i}>
                    <div className="author">
                        <div className="author__image">
                        <img src={`assets/img/testimonial/${val.testimonialImage}`} alt="" />
                        </div>
                        <div className="author__details">
                        <h4 className="name">{val.testimonialName}</h4>
                        <div className="designation">{val.testimonialDesignation}</div>
                        </div>
                    </div>
                    <div className="content">
                        {val.testimonialText}
                    </div>
                </div>
            )
        });
        
        return(
            <div>
                {/*====================  testimonial slider area ====================*/}
                <div className="testimonial-slider-area testimonial-slider-area-bg section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="testimonial-slider">
                        <div className="testimonial-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                            <div className="swiper-pagination"></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of testimonial slider area  ====================*/}

            </div>
        )
    }
}

export default TestimonialSlider;
