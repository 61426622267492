import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import PavingServiceGallery from './components/PavingServiceGallery';
class PavingService extends Component {
    render() {
        return (
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Professional Paving Services for Durable and Smooth Surfaces</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/services`}>Services</a></li>
                                        <li>Paving Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        {/* service gallery */}
                                        <PavingServiceGallery />

                                        <div className="content section-space--top--30">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2>Service Description</h2>
                                                    Our paving services encompass a wide range of projects, including:
                                                    <br />
                                                    <b>Driveways:</b> Enhance the curb appeal of your property with our expert driveway paving solutions. We offer a variety of materials and design options to suit your preferences, ensuring a sturdy and visually appealing driveway that stands the test of time.
                                                    <br />
                                                    <b>Parking Lots:</b> We specialize in the construction and maintenance of parking lots, catering to commercial, residential, and institutional clients. Our team ensures proper grading, drainage, and line striping for efficient traffic flow and organized parking spaces.
                                                    <br />
                                                    <b>Pathways and Walkways:</b> Create safe and attractive pathways for pedestrians with our expert paving services. We offer a range of materials, patterns, and textures to suit your specific needs, whether it's for a residential community, commercial complex, or recreational area.
                                                    <br />
                                                    <b>Industrial Pavements:</b> Our expertise extends to industrial paving projects, including warehouse floors, manufacturing facilities, and loading docks. We understand the unique requirements of these environments and deliver durable pavements that can withstand heavy equipment, chemicals, and high traffic.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Quality Assurance</h2>
                                                    At Patriots Pavement Services, we prioritize quality and customer satisfaction. Here's what you can expect when you choose our paving services:
                                                    <br />
                                                    <b>Skilled Team:</b> Our experienced paving crew is highly trained and knowledgeable in all aspects of pavement construction and repair.
                                                    <br />
                                                    <b>Premium Materials:</b> We use high-quality asphalt or concrete materials sourced from trusted suppliers to ensure long-lasting results.
                                                    <br />
                                                    <b>State-of-the-Art Equipment:</b> We utilize advanced machinery and tools to achieve precise grading, compaction, and surface finishing.
                                                    <br />
                                                    <b>Attention to Detail:</b> From proper site preparation to meticulous finishing touches, we pay close attention to every aspect of the paving process.
                                                    <br />
                                                    <b>Timely Execution:</b> We understand the importance of meeting project deadlines, and we strive to complete projects on time and within budget.
                                                </div>
                                                <div className="col-12">
                                                    <h2>Conclusion</h2>
                                                    <p>
                                                        When it comes to paving services, Patriots Pavement Services is your reliable partner for exceptional craftsmanship and reliable results. Whether it's for driveways, parking lots, roadways, pathways, or industrial pavements, our experienced team is ready to transform your vision into reality. Contact us today to discuss your paving needs and schedule a consultation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <div>
                                        <div className="sidebar-wrapper">
                                            <div className="sidebar">
                                                <h3 className="sidebar-title">Introduction</h3>
                                                <ul className="sidebar-list">
                                                    <p>
                                                        At Patriots Pavement Services, we take pride in providing top-notch paving services that create durable and smooth surfaces for a variety of applications. Whether you need a new pavement installation or repairs for your existing surfaces, our experienced team is equipped to deliver exceptional results. With our commitment to quality craftsmanship and attention to detail, we are your trusted paving partner.
                                                    </p>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default PavingService;