import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';

import TestimonialSlider from '../components/TestimonialSlider';

import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video'
{/*import Funfact from '../components/Funfact';
import TeamMemberGrid from '../components/TeamMemberGrid';
import BrandLogoSlider from '../components/BrandLogoSlider';*/}
class About extends Component {

    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <div>

                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>About Us</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--120">
                    {/*About section start*/}
                    <div className="about-section section-space--inner--bottom--120">
                        <div className="container">
                            <div className="row row-25 align-items-center">
                                <div className="col-lg-6 col-12 mb-30">
                                    <div className="about-image-two">
                                        <img src="assets/img/about/IMG_5972.JPG" alt="" />

                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                    <div className="about-content-two">
                                        <div className="about-content-two">
                                            <h3>Welcome to Patriot Pavement Services</h3>
                                            <h1>15 Years of Experience in Industry</h1>
                                            <h4>We are ready to partner with you on your project, no matter how big or small it is.</h4>
                                            <p>Patriot Pavement Services is an award-winning, full service paving and pavement maintenance company based in Boston, serving the Greater Boston Area. In an industry that is typically known for lack of professionalism and customer service. Patriot Pavement Services was founded with the vision to provide the Great Boston Area with quality, dependable and professional paving contractor, delivering a customer focused experience and unmatched quality. </p>
                                            <a href="services" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Our Services</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*About section end*/}

                        {/* Feature Icon */}
                        <FeatureIcon background="grey-bg" />

                        {/*About section start*/}
                        <div className="about-section section-space--inner--120">
                            <div className="container">
                                <div className="about-wrapper row">
                                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                                        <div className="about-image about-image-1">
                                            <img src="assets/img/about/about-1.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                                        <div className="about-image about-image-2">
                                            <img src="assets/img/about/IMG_5970.JPG" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                                        <div className="about-content about-content-1">
                                            <h1><span>Clients</span> always come first</h1>
                                            <p>We have always been customer focused, since we started 15 years ago, we have helped hundreds of customers get the best value. We lay out your parking lots and roadways, this way we can ensure that you get the most spaces and efficient traffic flow. Patriot Pavement Services also uses green products for environmentally sensitive areas.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 order-4">
                                        <div className="about-content about-content-2">
                                            <p> With more than 15 years experience our team understands that the image of a business starts at the parking lot. While parking lot maintenance can range from debris removal to pothole refilling and/or crack sealing. Patriot Pavement can help you protect your parking lot from water damage, crack seepage, making sure that you maximize parking lot lifespan. </p>
                                            <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Contact us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*About section end*/}

                        {/* Fun fact
                <Funfact/>*/}

                        {/* Team member
                <TeamMemberGrid/>*/}

                        {/* Testimonial Slider 
                <TestimonialSlider/>
                */}

                        {/* Brand logo
                <BrandLogoSlider background = "" /> */}

                    </div>


                    {/* Footer */}
                    <Footer />

                    {/* Mobile Menu */}
                    <MobileMenu />

                </div>
            </div>
        )
    }
}


export default About;
